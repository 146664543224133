import React, { useContext } from "react";
import { Card, Button, Alert, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import StripePricingPage from "../Components/StripePricingPage";
import AuthContext from "../context/AuthContext";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";

export default function Pricing() {
  const context = useContext(AuthContext);
  const metaData = useContext(AppMetaDataContext);

  // get the query strings from the URL and put them to an object
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const paramsObj = {};
  for (const [key, value] of urlParams.entries()) {
    paramsObj[key] = value;
  }

  let planBadge;

  switch (context.plan) {
    case "Free plan":
      planBadge = (
        <Badge bg="danger" text="dark">
          {context.plan}
        </Badge>
      );
      break;
    case "Premium plan":
      planBadge = (
        <Badge bg="dark" text="light">
          {context.plan}
        </Badge>
      );
      break;
    default:
      break;
  }

  // check if ?subscription=true in the URL and initialize auth again to make sure plan is updated

  return (
    <>
      <Helmet>
        <title>Pricing - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div
        className="px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center"
        style={{ maxWidth: "800px" }}
      >
        {paramsObj["extension_translation_quota_exceeded"] ? (
          <Alert variant="danger">
            <Alert.Heading>
              Quota exceeded for the sentence tranlsations of the browser
              extension
            </Alert.Heading>
            <p>
              Please upgrade to continue using the sentence translation. Or
              switch to word translation.
            </p>
          </Alert>
        ) : (
          <></>
        )}

        <h1 className="display-4">Pricing</h1>
        <p className="lead">Learn languages through native speaker content.</p>
        <br />

        {context.user_id ? (
          <>
            <div className="mb-1">Your current plan is: {planBadge}</div>
            <div className="mb-3">
              Need to cancel your current plan? Go to{" "}
              <Link to="/account">Account Settings</Link>.
            </div>
            {process.env.NODE_ENV !== "production" && context.user_id && (
              <Alert variant="warning">
                &#128161;{" "}
                <strong>
                  Use 4242 4242 4242 4242 as the credit card number
                </strong>{" "}
                for testing purposes. The name etc. can be random. Just make
                sure the credit card expiration date is in the future.
              </Alert>
            )}
            <StripePricingPage />
          </>
        ) : (
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <Card className="mb-5" style={{ maxWidth: "350px" }}>
                  <Card.Header as="h5">Free plan</Card.Header>
                  <Card.Body>
                    <Card.Title className="mb-4">
                      Free
                      <br />
                      <span style={{ fontSize: "1rem" }}> &nbsp;</span>
                    </Card.Title>
                    <Card.Text>
                      Unlimited news articles to study
                      <br />
                      <br />
                      Unlimited word lookups
                      <br />
                      <br />
                      Unlimited AI explanations
                      <br />
                      <br />
                      Flashcards
                      <br />
                      <br />
                      Unlimited videos to study
                      <br />
                      <br />
                      <strong>Max 10</strong> imported YouTube captions in total
                      <br />
                      <br />
                      Statistics
                      <br />
                      <br />
                      <strong>Max 100</strong> saved words & phrases
                      <br />
                      <br />
                      <strong>Max 10</strong> AI-generated content in total
                      <br />
                      <br />
                      Chrome extension for translating words and sentences on
                      any website (<strong>max 20k characters in total</strong>)
                      <br />
                      <br />
                    </Card.Text>
                    <Button variant="success" href="/register">
                      Try for free
                    </Button>
                  </Card.Body>
                </Card>
              </div>

              <div className="col-12 col-md-6">
                <Card className="mb-5" style={{ maxWidth: "350px" }}>
                  <Card.Header as="h5">Premium plan</Card.Header>
                  <Card.Body>
                    <Card.Title className="mb-4">
                      <span>$4.99</span>
                      <br />
                      <br />
                    </Card.Title>
                    <Card.Text>
                      Unlimited news articles to study
                      <br />
                      <br />
                      Unlimited word lookups
                      <br />
                      <br />
                      Unlimited AI explanations
                      <br />
                      <br />
                      Flashcards
                      <br />
                      <br />
                      Unlimited videos to study
                      <br />
                      <br />
                      <strong>Unlimited</strong> imported YouTube captions
                      <br />
                      <br />
                      Statistics
                      <br />
                      <br />
                      <b>Unlimited</b> saved words & phrases
                      <br />
                      <br />
                      <b>Max 100</b> AI-generated content per month
                      <br />
                      <br />
                      Chrome extension for translating words and sentences on
                      any website (
                      <strong>max 100k characters per month</strong>)
                      <br />
                      <br />
                    </Card.Text>
                    <Button variant="primary" href="/register">
                      Sign up
                    </Button>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
