import React, { useState, useEffect, useTransition } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import { Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import AppMetaDataContext from "../context/AppMetaDataContext";
import AuthContext from "../context/AuthContext";
import { Helmet } from "react-helmet-async";
import ContinueStudying from "../Components/ContinueStudying";
import { checkIfWordIsKnown, splitByURLs } from "../utils/ReaderUtils";
import axios from "axios";
import OnboardingModal from "../Components/OnboardingModal";
import LibraryCard from "../Components/LibraryCard";
import VideoCard from "../Components/VideoCard";
import SongCard from "../Components/SongCard";
import { useLocation } from "react-router-dom";
import { Magic, Play } from "react-bootstrap-icons";
import "./Library.css";
import Playlist from "../Components/Playlist";


export default function Library() {
  const metaData = React.useContext(AppMetaDataContext);
  const context = React.useContext(AuthContext);

  // Initialize states
  const [key, setKey] = useState("news");
  const [articles, setArticles] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState(new Set());
  const [uniqueCategories, setUniqueCategories] = useState([]);
  const [articlesAreLoading, setArticlesAreLoading] = useState(false);
  const [isArticlesLoading, startArticlesTransition] = useTransition();
  const [songs, setSongs] = useState([]);
  const [songsAreLoading, setSongsAreLoading] = useState(false);
  const [currentArticlesPage, setCurrentArticlesPage] = useState(1);
  const [maxNewWordsPercentage, setMaxNewWordsPercentage] = useState(100);
  const [tempMaxNewWordsPercentage, setTempMaxNewWordsPercentage] =
    useState(100);
  const [importedArticlesAreLoading, setImportedArticlesAreLoading] =
    useState(false);
  const [importedArticles, setImportedArticles] = useState([]);
  const [recentVideosAreLoading, setRecentVideosAreLoading] = useState(false); // Added for YouTube videos
  const [recentVideos, setRecentVideos] = useState([]); // Added for YouTube videos
  const [
    articleKnownWordsNeedRecalculation,
    setArticleKnownWordsNeedRecalculation,
  ] = useState(false);
  const [isStudyArticlesLoading, startStudyArticlesTransition] = useTransition();

  const location = useLocation();

  useEffect(() => {
    // Parse the URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");

    // Check if the 'tab' query parameter is set to 'imported'
    if (tab === "imported") {
      setKey("imported"); // Update the state to set the tab key to 'imported'
    } else if (tab === "playlists") {
      setKey("playlists");
    } else if (tab === "videos") {
      setKey("videos");
    } else if (tab === "songs") {
      setKey("songs");
    }
  }, [location]);

  // Set states with imported articles data
  useEffect(() => {
    startArticlesTransition(() => getArticles());
    startStudyArticlesTransition(() => context.fetchStudyArticles());
  }, []);

  //useeffect for location
  useEffect(() => {
    console.log(
      "location changed, so, should start fetching new known words",
      window.location.href
    );
    context.fetchKnownWords();
  }, [window.location.href]);

  // useEffect for categories
  useEffect(() => {
    const uniqueCategoriesSet = new Set();
    articles.forEach((article) => {
      article.categories.forEach((category) => {
        if (!category.includes("dmoz")) {
          uniqueCategoriesSet.add(category); // Changed from category.label
        }
      });
    });
    setUniqueCategories(Array.from(uniqueCategoriesSet));
  }, [articles]);

  function recalculateKnownWords() {
    console.log(
      `Known words in context changed to ${context.known_words?.length}. Recalculating...`
    );
    console.log("Articles before recalculation: ", articles);
    const updatedArticles = calculateKnownWordsPerArticle(articles);
    console.log("Updated articles for known words: ", updatedArticles);
    setArticles(updatedArticles);
  }

  useEffect(() => {
    if (context.known_words) {
      recalculateKnownWords();
    }
  }, [context.known_words]);

  useEffect(() => {
    if (context.known_words && articleKnownWordsNeedRecalculation) {
      recalculateKnownWords();
    }
    setArticleKnownWordsNeedRecalculation(false);
  }, [articleKnownWordsNeedRecalculation]);

  async function getArticles() {
    setArticlesAreLoading(true);
    // loop through context.language_pairs and find the one that has is_selected=true
    // and return the language_learning value
    let language_learning;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("pair: ", pair);
        if (pair.is_selected) {
          language_learning = pair.language_learning.code;
          // language_base = pair.language_base;
          break;
        }
      }
    }

    try {
      console.log(
        "Trying to get articles from the backend for the language: ",
        language_learning
      );
      const response = await axios.get("/api/articles", {
        params: {
          language: language_learning, // Replace with the language you want to filter by
          page: currentArticlesPage, // Replace with the current page number you want to request
          limit: 30, // Replace with the number of articles per page you want
        },
      });

      setCurrentArticlesPage((prevPage) => prevPage + 1);

      console.log(
        "Got the articles from the backend: ",
        response.data.articles
      );
      let articlesWithKnownWords = calculateKnownWordsPerArticle(
        response.data.articles
      );
      setArticles((prevArticles) => [
        ...prevArticles,
        ...articlesWithKnownWords,
      ]);
      setArticleKnownWordsNeedRecalculation(true);

      setArticlesAreLoading(false);
    } catch (error) {
      console.error(
        "There was a problem with getting the articles from the backend: ",
        error
      );
      setArticlesAreLoading(false);
    }
  }

  function calculateKnownWordsPerArticle(articleArray) {
    let knownWordsArray = context.known_words;
    console.log(
      "Known words array when starting to calculate: ",
      context.known_words?.length
    );
    return articleArray.map((article) => {
      const splitByURL = splitByURLs(article.body);
      const finalWords = splitByURL.flatMap((fragment) => {
        if (!fragment.match(/https?:\/\/[^\s]+/)) {
          return fragment.split(/\P{L}+/gu).filter((token) => token !== "");
        } else {
          return [fragment];
        }
      });

      const finalArticleTitle = article.title
        .split(/\P{L}+/gu)
        .filter((token) => token !== "");

      let combinedWords = finalWords.concat(finalArticleTitle);
      let knownWordsCount = combinedWords.filter((word) =>
        checkIfWordIsKnown(word, context.known_words || [])
      ).length;

      let percentageKnownWords = Math.round(
        (knownWordsCount / combinedWords.length) * 100
      );

      return {
        ...article,
        percentage_unknown_words: 100 - percentageKnownWords,
        percentage_known_words: percentageKnownWords,
      };
    });
  }

  const filterArticlesByNewWords = (articles) => {
    return articles.filter(
      (article) => article.percentage_unknown_words <= maxNewWordsPercentage
    );
  };

  const checkIfArticleIsBookmarked = (articleId) => {
    // check if the article id is in the continue studying list
    return context.study_articles.some((article) => article._id === articleId);
  };

  // Handle checkbox toggle for category filter
  const handleCategoryToggle = (category) => {
    const newSelectedCategories = new Set(selectedCategories);
    if (newSelectedCategories.has(category)) {
      newSelectedCategories.delete(category);
    } else {
      newSelectedCategories.add(category);
    }
    setSelectedCategories(newSelectedCategories);
  };

  const categoryFilteredArticles =
    selectedCategories.size === 0
      ? articles
      : articles.filter((article) =>
          article.categories.some((category) =>
            selectedCategories.has(category)
          )
        );

  const filteredArticles = categoryFilteredArticles.filter(
    (article) => article.percentage_unknown_words <= maxNewWordsPercentage
  );

  function getRecentVideos() {
    setRecentVideosAreLoading(true);
    // send a request to the backend to get the videos
    axios
      .get("/api/recent-videos", {
        params: {
          language: context.getSelectedLanguagePair().language_learning.code,
        },
      })
      .then((res) => {
        console.log("Got the videos from the backend: ", res.data.videos);
        setRecentVideos(res.data.videos);
        setRecentVideosAreLoading(false);
      })
      .catch((error) => {
        console.error("Error getting videos:", error);
        setRecentVideosAreLoading(false);
      });
  }

  function getImportedArticles() {
    setImportedArticlesAreLoading(true);
    // get the current learning language code from context
    let language_learning =
      context.getSelectedLanguagePair().language_learning.code;
    // make axios call to backend
    axios
      .get(`/api/user/articles/imported`, {
        params: {
          language: language_learning, // Replace 'en' with the language you want to filter by
        },
      })
      .then((res) => {
        console.log(
          "Got the imported articles from the backend: ",
          res.data.articles
        );
        let importedArticlesWithKnownWords = calculateKnownWordsPerArticle(
          res.data.articles
        );
        setImportedArticles(importedArticlesWithKnownWords);

        setImportedArticlesAreLoading(false);
        // setImportedArticles(res.data.articles);
      })
      .catch((error) => {
        console.error("Error getting imported articles:", error);
        setImportedArticlesAreLoading(false);
      });
  }

   function getSongs() {
    setSongsAreLoading(true);
    // get the current learning language code from context
    let language_learning_code =
      context.getSelectedLanguagePair().language_learning.code;
    // make axios call to backend
    axios
      .get(`/api/songs`, {
        params: {
          language: language_learning_code,
        },
      })
      .then((res) => {
        console.log(
          "Got the songs from the backend: ",
          res.data.songs
        );
        // let songsWithKnownWords = calculateKnownWordsPerArticle(res.data.songs); // NOTE: can't use this for now bc there are properties which don't exist for songs
        setSongs(res.data.songs);

        setSongsAreLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching songs:", error);
        setSongsAreLoading(false);
      });
  }

  useEffect(() => {
    if (key === "imported") {
      console.log("Trying to get imported articles from the backend.");
      getImportedArticles();
    } else if (key === "videos") {
      console.log("Trying to get videos from the backend.");
      getRecentVideos();
    } else if (key === "songs") {
      console.log("Trying to get songs from the backend.");
      getSongs();
    }
  }, [key]);

  return (
    <Container
      className="d-flex pt-2 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Library - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      {context.study_articles.length > 0 && (
        <ContinueStudying />
      )}

      <Tabs
        id="library-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 tabHeading"
      >
        <Tab eventKey="news" title="News feed">
          <div className="" style={{ maxWidth: "700px" }}></div>

          {/* Category Filter */}
          {/* NOTE: it would look better with buttons but on touchscreen it seems to be impossible to remove focus
      and focused buttons look the same as selected ones*/}
          <div className="mb-3 mx-2">
            <h6>Filter by category</h6>
            {/* <h5>Filter articles by category:</h5> */}
            {uniqueCategories.map((category, index) => (
              <div className="form-check form-check-inline" key={index}>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={`category-${index}`}
                  checked={selectedCategories.has(category)}
                  onChange={() => handleCategoryToggle(category)}
                />
                <label
                  className="form-check-label"
                  htmlFor={`category-${index}`}
                >
                  {category}
                </label>
              </div>
            ))}
          </div>

          <div className={`mx-2 ${!context.known_words && "d-none mt-2"}`}>
            <h6>Filter by difficulty</h6>

            <div className="d-flex align-items-center mb-3">
              <label
                htmlFor="maxNewWordsPercentage"
                className="form-label me-2"
              >
                Max % of new words in an article: {tempMaxNewWordsPercentage}%
              </label>
              <input
                type="range"
                className="form-range"
                id="maxNewWordsPercentage"
                min="0"
                max="100"
                step="10"
                value={tempMaxNewWordsPercentage}
                onChange={(e) =>
                  setTempMaxNewWordsPercentage(Number(e.target.value))
                }
                onMouseUp={() =>
                  setMaxNewWordsPercentage(tempMaxNewWordsPercentage)
                }
                onTouchEnd={() =>
                  setMaxNewWordsPercentage(tempMaxNewWordsPercentage)
                }
                style={{ maxWidth: "200px", height: "auto" }}
              />
            </div>
          </div>

          {filteredArticles.length === 0 && !articlesAreLoading && (
            <Alert variant="secondary">
              No articles to show. Please adjust the filters or try loading more
              articles.
            </Alert>
          )}

          {!context.known_words?.length === 0 &&
            !articlesAreLoading &&
            maxNewWordsPercentage !== 100 && (
              <Alert variant="warning">
                NB! You don't have any words in your vocabulary yet.{" "}
                <strong>
                  Start reading the articles and we'll add them automatically as
                  you turn the pages.
                </strong>{" "}
                Or you can add them manually during article reading or via
                import.
              </Alert>
            )}

          <Row>
            {filteredArticles.map((article, index) => {
              return (
                <LibraryCard article={article} index={index} key={index} is_bookmarked={checkIfArticleIsBookmarked(article._id)} />
              );
            })}
          </Row>
          <Row>
            <Col>
              {" "}
              {/* Create container with width to maxwidth and justify center content  */}
              {/* <Container className="justify-content-center d-flex"> */}
              <Button
                onClick={getArticles}
                id="loadMore"
                variant="outline-dark"
                disabled={articlesAreLoading}
              >
                {articlesAreLoading ? (
                  <>
                    {" "}
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                      className="me-2"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                    Loading...
                  </>
                ) : (
                  "Load more news"
                )}
              </Button>
              {/* </Container> */}
            </Col>
          </Row>
        </Tab>
        {/* tab for Playlist */}
        <Tab eventKey="playlists" title="Playlists">
          <Playlist activeKey={key} />
        </Tab>
        
        {/* tab for Songs/Music */}
        <Tab eventKey="songs" title="Songs">
          <div>
            {context.email.includes("atjackiejohns") && (
            <Link to="/library/import-song">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center"
              >
                &#43; Import song
              </Button>
              </Link>
            )}

            {/* <Link to="/vocabulary/import">
              <Button
                size="sm"
                className="me-2 d-flex-inline align-items-center"
                variant="outline-primary"
              >
                &#43; Import words
              </Button>
            </Link> */}
          </div>
          {!songsAreLoading && songs.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; Either the server failed or there are no songs for this language yet.
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {songs.map((song, index) => {
              return (
                <SongCard
                  song={song}
                  index={index}
                  key={index}
                  is_imported={true}
                  is_bookmarked={checkIfArticleIsBookmarked(song._id)}
                />
              );
            })}
          </Row>
        </Tab>

        <Tab eventKey="videos" title="Videos">
          {!recentVideosAreLoading && recentVideos.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; No videos to show yet. Please contact support if this problem persists.
            </Alert>
          )}
          {recentVideosAreLoading && (
            <Alert variant="light" className="mt-4">
              <>
                <Spinner
                  animation="border"
                  role="status"
                  size="sm"
                  className="me-2"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                Loading recent videos...
              </>
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {recentVideos.map((video, index) => {
              return <VideoCard video={video} index={index} key={index} />;
            })}
          </Row>
        </Tab>
        <Tab eventKey="imported" title="Imported">
          <div>
            <Link to="/library/editor">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center"
              >
                &#43; Import content
              </Button>
            </Link>
            <Link to="/library/generator">
              <Button
                size="xl"
                className="mt-2 me-2 d-flex-inline align-items-center btn-dark"
              >
                <Magic className="me-1 mb-1" /> Create content with AI
                <sup>
                  <Badge bg="warning" className="ms-2">
                    New!
                  </Badge>
                </sup>
              </Button>
            </Link>

            {/* <Link to="/vocabulary/import">
              <Button
                size="sm"
                className="me-2 d-flex-inline align-items-center"
                variant="outline-primary"
              >
                &#43; Import words
              </Button>
            </Link> */}
          </div>
          {!importedArticlesAreLoading && importedArticles.length === 0 && (
            <Alert variant="secondary" className="mt-4">
              &#128566; No imported content yet.
            </Alert>
          )}
          <Row className="mb-5 mt-4">
            {importedArticles.map((article, index) => {
              return (
                <LibraryCard
                  article={article}
                  index={index}
                  key={index}
                  is_imported={true}
                  is_bookmarked={checkIfArticleIsBookmarked(article._id)}
                />
              );
            })}
          </Row>
        </Tab>
        <Tab title={<OnboardingModal />}>&nbsp;</Tab>
      </Tabs>
    </Container>
  );
}
