import React from "react";
import {
  Button,
  Row,
  Col,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import linkchimpImage from "../screenshot-front-combo-2.png";

// import AppMetaDataContext from "../context/AppMetaDataContext";

function LearnDuolingoVocabularyMainCTA( {language} ) {
  // const metaData = React.useContext(AppMetaDataContext);

    return (
      <div>
        <Row style={{ margin: "0% 3%", marginBottom: "5%" }}>
          <Col xs={{ span: 12, order: 2 }} lg={{ span: 6, order: 1 }}>
            <h4 className="display-5 mb-5">
              From intermediate to fluent{" "}
              {language ? language : ""}
            </h4>
            <h4>
              Pick up languages by reading, listening, and watching {language ? language : "native speaker"} content of your choice.
            </h4>
            <br />
            <LinkContainer to="/register">
              <Button size="lg" variant="success">
                Try it out for free
              </Button>
            </LinkContainer>
            <br />
            <br />
            <p>No credit card required.</p>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            lg={{ span: 6, order: 2 }}
            style={{ paddingBottom: "5%" }}
          >
            <img src={linkchimpImage} alt="Logo" className="img-fluid" />
          </Col>
        </Row>
        <br />
        <br />
        <hr />
      </div>
    );
}

export default LearnDuolingoVocabularyMainCTA;
