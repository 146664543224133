import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import AppMetaDataContext from "../context/AppMetaDataContext";
import AuthContext from "../context/AuthContext";
import "./Footer.css";

function Footer(props) {
  const metaData = useContext(AppMetaDataContext);
  const context = useContext(AuthContext);

  const location = useLocation();
  const RoutesWithoutFooter = [
    "/reader",
    "/review",
    "/vocabulary",
    "/statistics",
    "/library",
    "/pricing",
    "/contact",
    "/account",
  ];
  const RoutesWithoutFooterAndUser = [
    "/login",
    "/register",
    "/forgot-password",
  ];

  let showReducedContent =
    RoutesWithoutFooter.some(
      (route) =>
        location.pathname.startsWith(route + "/") || location.pathname === route
    ) && context.user_id;

  if (
    RoutesWithoutFooterAndUser.some(
      (route) =>
        location.pathname.startsWith(route + "/") || location.pathname === route
    )
  ) {
    showReducedContent = true;
  }

  const courses = [
    { name: "Danish" },
    {
      name: "Estonian",
    },
    { name: "French" },
    { name: "Finnish" },
    { name: "German" },
    { name: "Italian" },
    {
      name: "Latvian",
    },
    { name: "Norwegian" },

    { name: "Portuguese" },
    { name: "Russian" },
    {
      name: "Spanish",
    },
    { name: "Swedish"},
    { name: "Thai" },
  ];

  const reducedContent = (
    <Container
      className="text-center"
      style={{ marginTop: "5%" }}
      id="footer-container"
    >
      <hr className="mb-5" />
      <Row>
        <Col className="text-center mb-5">
          <a
            href="https://lingochampion.freshdesk.com"
            target="_blank"
            style={{ color: "black" }}
          >
            Support
          </a>{" "}
          |{" "}
          <a
            href="https://tally.so/r/wbW5X1"
            target="_blank"
            style={{ color: "black" }}
          >
            Feedback & bugs
          </a>{" "}
          |{" "}
          <LinkContainer to="/release-notes">
            <a style={{ color: "black" }}>What's new?</a>
          </LinkContainer>{" "}
        </Col>
      </Row>
    </Container>
  );

  const fullContent = (
    <Container
      className="text-center"
      style={{ marginTop: "5%" }}
      id="footer-container"
    >
      <hr className="mb-5" />
      <Row className="mb-3">
        {/* Column for Language Course Links */}
        <Col md={3} className="order-1"></Col>
        <Col
          md={3}
          className="d-flex justify-content-center mb-5 order-3 order-sm-2"
        >
          <div className="text-md-start text-center text-nowrap">
            <h5>Languages</h5>
            {/* Add your language course links here */}
            {courses.map((course, index) => (
              <div key={index}>
                <LinkContainer
                  to={`/course/${course.name.toLowerCase()}-learn-online`}
                >
                  <a style={{ color: "black" }}>Learn {course.name} online</a>
                </LinkContainer>
              </div>
            ))}
            <div className="mt-3">
              <LinkContainer to="/comprehensible-input">
                <a style={{ color: "black" }}>Comprehensible input</a>
              </LinkContainer>
            </div>
          </div>
        </Col>

        {/* Column for Existing Links */}
        <Col
          md={3}
          className="d-flex justify-content-center mb-5 order-2 order-sm-3"
        >
          <div className="text-md-start text-center">
            <h5>Product</h5>
            <div>
              <LinkContainer to="/release-notes">
                <a style={{ color: "black" }}>What's new?</a>
              </LinkContainer>{" "}
            </div>
            <div>
              <a
                href="https://tally.so/r/wbW5X1"
                target="_blank"
                style={{ color: "black" }}
              >
                Feedback & bugs
              </a>
            </div>
            <div>
              <a
                href="https://lingochampion.freshdesk.com"
                target="_blank"
                style={{ color: "black" }}
              >
                Support
              </a>
            </div>
            <div>
              <LinkContainer to="/privacy-policy">
                <a style={{ color: "black" }}>Privacy policy</a>
              </LinkContainer>{" "}
            </div>
            <div>
              <LinkContainer to="/terms-of-service">
                <a style={{ color: "black" }}>Terms of service</a>
              </LinkContainer>
            </div>
            <h5 className="mt-5">About</h5>
            <div>
              <LinkContainer to="/home">
                <a style={{ color: "black" }}>Home</a>
              </LinkContainer>{" "}
            </div>
            <div>
              <LinkContainer to="/pricing">
                <a style={{ color: "black" }}>Pricing</a>
              </LinkContainer>{" "}
            </div>
            <div>
              <LinkContainer to="/contact">
                <a style={{ color: "black" }}>Contact</a>
              </LinkContainer>{" "}
            </div>
          </div>
        </Col>
        <Col md={3} className="order-4"></Col>
      </Row>
      <Row>
        <Col className="text-center mb-5">
          © {new Date().getFullYear()} {metaData.appName}
        </Col>
      </Row>
    </Container>
  );

  return showReducedContent ? reducedContent : fullContent;
}

export default Footer;
