import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Spinner from "react-bootstrap/Spinner";
import WordEdit from "../Components/WordEdit";
import "./TranslationPanel.css";
import AIBrainImg from "../ai-brain-3.png";
import { Robot, Magic } from "react-bootstrap-icons";
import SpeechButton from "../Components/SpeechButton";
import StrengthBar from "../Components/StrengthBar";

const TranslationPanel = ({
  selectedWord,
  addUnknownWordToKnownWords,
  updateKnownWordInKnownWords,
  isFullScreen,
  closeTranslationsPanel,
  fullTextTokens,
  clearTranslationPanelSelectedWord,
  speakOnRender = true,
}) => {
  // TODO: remove this
  const { known_words, markWordAsKnown, updateWordTranslation } =
    useContext(AuthContext);
  const context = useContext(AuthContext);

  const [possibleTranslations, setPossibleTranslations] = useState([]);
  const [currentExplanation, setCurrentExplanation] = useState(null);
  const [localSelectedWord, setLocalSelectedWord] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isAILoading, setIsAILoading] = useState(false);
  const currentWordRef = useRef(localSelectedWord);

  useEffect(() => {
    console.log("Translation panel received the word: ", selectedWord);
    console.log("Setting it as localSelectedWord");
    // NOTE: props do trigger a re-render when they change, but they're read only
    setLocalSelectedWord({ ...selectedWord }); // creates a new object instead of referencing the selectedWord prop
  }, [selectedWord]);

  useEffect(() => {
    console.log("localSelectedWord changed: ", localSelectedWord);

    // reset the possible translations
    setPossibleTranslations([]);

    // find the active language pair codes
    const selectedLanguagePair = context.language_pairs.find(
      (pair) => pair.is_selected
    );
    console.log("Active language pair: ", selectedLanguagePair);

    if (!selectedWord.word || !localSelectedWord.word) {
      // show the instructions when no word is selected
      document.getElementById("translationsPanelInstructions").style.display =
        "block";
      return;
    }
    // hide the instructions when looking up a word
    document.getElementById("translationsPanelInstructions").style.display =
      "none";

    // send word to OpenAI for explanation
    setIsAILoading(true);
    axios
      .post("/api/explain-word", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      })
      .then((response) => {
        console.log("RESPONSE FROM EXPLAIN ONE WORD: ", response.data);
        console.log("--- ORIGINAL WORD: ", response.data.original);
        console.log("--- LOCAL SELECTED WORD: ", localSelectedWord.word);

        // Compare against the current value in the ref
        // NOTE: dunno why localSelectedWord.word doesn't work here properly when clicking on multiple words fast

        if (response.data.original === currentWordRef.current.word) {
          const formattedExplanation = escapeQuotesAndNewlines(response.data.translation);
          setCurrentExplanation(
            // decodeUnicodeEscapeSequences(
              formattedExplanation
            // )
          );
          // add term_possible_translations to the end of possibleTranslations
          // setPossibleTranslations((prevTranslation) => {
          //   const newValues =
          //     response.data.translation.term_possible_translations.filter(
          //       (value) => !prevTranslation.includes(value)
          //     );
          //   return [...prevTranslation, ...newValues];
          // });

          console.log("-- INSERTED EXPLANATION");
          setIsAILoading(false);
        } else {
          console.log("-- SKIPPED EXPLANATION: Word has changed");
        }
      })
      .catch((err) => {
        setIsAILoading(false);
        setCurrentExplanation("No AI explanation available for this term.");
        console.log("Error from explaining word backend: ", err);
      });

    axios
      .post("/api/translate-word-with-ai", {
        word: selectedWord.word,
        context: selectedWord.sentence,
        language_learning: selectedLanguagePair.language_learning.name,
        language_base: selectedLanguagePair.language_base.name,
      })
      .then((response) => {
        console.log(
          "RESPONSE FROM TRANSLATE ONE WORD WITH AI: ",
          response.data
        );
        console.log("--- ORIGINAL WORD: ", response.data.original);
        console.log("--- LOCAL SELECTED WORD: ", localSelectedWord.word);

        // Compare against the current value in the ref
        // NOTE: dunno why localSelectedWord.word doesn't work here properly when clicking on multiple words fast
        if (response.data.original === currentWordRef.current.word) {
          // decode the unicode escape sequences
          response.data.translation.term_possible_translations =
            response.data.translation.term_possible_translations.map(
              (translation) => decodeUnicodeEscapeSequences(translation)
            );

          // console.log(`AI translation (${response.data.original}) matches the current word in translation panel (${localSelectedWord.word}).`);
          // setCurrentExplanation(response.data.translation.term_explanation);
          // add term_possible_translations to the end of possibleTranslations
          setPossibleTranslations((prevTranslation) => {
            const newValues =
              response.data.translation.term_possible_translations.filter(
                (value) => !prevTranslation.includes(value)
              );
            return [...prevTranslation, ...newValues];
          });

          console.log("-- INSERTED AI TRANSLATIONS");
        } else {
          console.log("-- SKIPPED AI TRANSLATIONS: Word has changed");
        }
      })
      .catch((err) => {
        setCurrentExplanation("No AI translations available for this term.");
        console.log("Error from AI translation word backend: ", err);
      });

    if (
      !localSelectedWord.translation &&
      !localSelectedWord.is_not_a_word &&
      localSelectedWord.word
    ) {
      console.log("DECIDED TO DO TRANSLATIONS: ", localSelectedWord);
      setIsLoading(true);
      // Fetch translation from the server
      const request = {
        word: selectedWord.word,
        language_learning: selectedLanguagePair.language_learning.code,
        language_base: selectedLanguagePair.language_base.code,
      };
      axios
        .post("/api/translate-word", request)
        .then((response) => {
          console.log("RESPONSE FROM TRANSLATE ONE WORD: ", response.data);
          setIsLoading(false);
          // check if the word is still the same
          if (response.data.original === currentWordRef.current.word) {
            // add the translations to the end of the translations array
            setPossibleTranslations((prevTranslation) => {
              const newValues = response.data.translation.filter(
                (value) => !prevTranslation.includes(value)
              );
              return [...prevTranslation, ...newValues];
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [localSelectedWord]);

  // useEffect for TTS
  useEffect(() => {
    if (!localSelectedWord.word || localSelectedWord.word === "") {
      // display instructions
      document.getElementById("translationsPanelInstructions").style.display =
        "block";
      return;
    }
    // Update the ref every time localSelectedWord changes
    currentWordRef.current = localSelectedWord;
  }, [localSelectedWord]);

  // REVIEW: this is probably a duplication of clearTranslationPanelSelectedWord
  function clearTranslationPanelSelectedWordContent() {
    console.log(
      "Clearing the translation panel content and displaying instruction again."
    );
    setLocalSelectedWord({});
    // show instructions
    // this is already done in the useffect:
    // document.getElementById("translationsPanelInstructions").style.display = "block";
  }

  function handleWordAddClick(e, translation) {
    e.stopPropagation();
    if (localSelectedWord.known) {
      updateKnownWordInKnownWords(localSelectedWord, translation, 0);
    } else {
      addUnknownWordToKnownWords(localSelectedWord.word, translation, 0);
    }
    clearTranslationPanelSelectedWordContent();
    if (isFullScreen) closeTranslationsPanel();
  }

  return (
    <div id="translationPanel">
      <TranslationPanelInstructions
      />
      {"word" in localSelectedWord && (
        <div id="translationPanelSelectedWordContent">
          <h5>
            {
              <SpeechButton
                word={localSelectedWord.word}
                speakOnRender={speakOnRender}
              />
            }
            {localSelectedWord.word}
          </h5>
          {"strength" in localSelectedWord && localSelectedWord.translation && (
            <>
              <p>Word strength: {localSelectedWord.strength}</p>
              {/* BUG: when saving the strength of an existing word, the word is not selected anymore in the translation panel */}
              {/* <StrengthBar word={localSelectedWord} setWord={setLocalSelectedWord} updateKnownWordInKnownWords={updateKnownWordInKnownWords} /> */}
              </>
          )}

          <div id="AIExplanation" className="mb-4 mt-3">
            <ExplanationBox
              currentExplanation={currentExplanation}
              isAILoading={isAILoading}
            />
          </div>

          {!localSelectedWord.known &&
            (!context.known_words || context.known_words?.length < 500) && (
              <>
                <p>
                  You haven't seen this word yet. Pick a meaning and add it as a{" "}
                  <span className="known">learning word</span>. Or skip it if
                  you already know it.
                </p>
              </>
            )}

          {localSelectedWord.known &&
            localSelectedWord.translation === undefined &&
            (!context.known_words || context.known_words?.length < 500) && (
              <>
                <p>
                  It's a word you skipped when turning pages. You probably know
                  this word but you haven't added a translation yet.
                </p>
              </>
            )}

          <h6 className="my-2">Saved translation</h6>
          <WordEdit
            localSelectedWord={localSelectedWord}
            handleWordAddClick={handleWordAddClick}
            clearTranslationPanelSelectedWordContent={
              clearTranslationPanelSelectedWordContent
            }
          />

          <div className="your-container-class">
            <h6 className="my-2">Possible translations</h6>
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              <ListGroup as="ul">
                {possibleTranslations.length > 0 &&
                  possibleTranslations.map((translation, index) => (
                    <ListGroup.Item
                      as="a"
                      className="d-flex justify-content-between align-items-start translationPanelListItem"
                      key={index}
                    >
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">{translation}</div>
                      </div>
                      <Button
                        onTouchStart={(e) => {
                          handleWordAddClick(e, translation);
                        }}
                        onClick={(e) => {
                          handleWordAddClick(e, translation);
                        }}
                        size="sm"
                      >
                        Add
                      </Button>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

function TranslationPanelInstructions() {
  return (
    <div id="translationsPanelInstructions">
      <p>
        &#128161; Click on any word to see (possible) translations and add them
        to your vocabulary for learning.
      </p>
      <h6>What do the word colors mean?</h6>
      <div className="my-3">
        <div className="mb-2">
          <OverlayTrigger
            placement="left"
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                You haven't seen this word yet in any text. Pick a meaning and
                add it as a learning word. Or skip it if you already know it.
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="unknown">new word</span>
          </OverlayTrigger>
        </div>
        <div className="mb-2">
          <OverlayTrigger
            placement="left"
            // set show property to false when visualviewport width is less than 480, otherwise don't use show property
            show={
              document.documentElement.clientWidth < 1024 ? false : undefined
            }
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                You saved this word as a learning word. Learning words let you
                keep track of your vocabulary growth. You can use them later for
                additional learning exercises. The strength of the word determines how strong the orange underline is.
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="known">learning word</span>
          </OverlayTrigger>
        </div>
        <div>
          <OverlayTrigger
            placement="left"
            // set show property to false when visualviewport width is less than 480, otherwise don't use show property
            show={
              document.documentElement.clientWidth < 1024 ? false : undefined
            }
            delay={{ show: 50, hide: 100 }}
            overlay={
              <Tooltip style={{ zIndex: 20000 }}>
                It's a word you skipped when turning pages. You probably know
                this word but you haven't added a translation yet.
              </Tooltip>
            }
            popperConfig={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 10], // Change the numbers to control x, y offset
                  },
                },
                {
                  name: "preventOverflow",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
                {
                  name: "flip",
                  options: {
                    padding: 10, // Change this value to control padding
                  },
                },
              ],
            }}
          >
            <span className="skipped">skipped word</span>
          </OverlayTrigger>
        </div>
      </div>
      <p>
        &#128161; All <span className="unknown">new words</span> will be marked
        as skipped (known) when you move to the next page.
      </p>
      <p>
        &#128161; Mark <span className="highlighted">multiple words</span> words to look up and save phrases.
      </p>
    </div>
  );
}

function findSentenceContainingToken(tokens, tokenIndex) {
  // Validate the tokenIndex
  if (tokenIndex < 0 || tokenIndex >= tokens.length) {
    throw new Error("Invalid token index");
  }

  // Find the start of the sentence
  let start = 0; // Assume start of array if no punctuation found
  for (let i = tokenIndex - 1; i >= 0; i--) {
    if ([".", "!", "?"].includes(tokens[i])) {
      start = i + 1;
      break;
    }
  }

  // Find the end of the sentence
  let end = tokens.length; // Assume end of array if no punctuation found
  for (let i = tokenIndex + 1; i < tokens.length; i++) {
    if ([".", "!", "?"].includes(tokens[i])) {
      end = i + 1;
      break;
    }
  }

  // Reconstruct and return the sentence
  return tokens.slice(start, end).join("");
}

function ExplanationBox({ currentExplanation, isAILoading }) {
  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textContainerRef = useRef(null);

  useEffect(() => {
    if (isAILoading) {
      // Reset isOverflowing when loading starts
      setIsOverflowing(false);
      setExpanded(false);
    } else {
      const checkOverflow = () => {
        const current = textContainerRef.current;
        if (current) {
          setIsOverflowing(current.scrollHeight > current.clientHeight);
        }
      };

      checkOverflow();
      window.addEventListener("resize", checkOverflow);

      return () => window.removeEventListener("resize", checkOverflow);
    }
  }, [isAILoading, currentExplanation]);

  useEffect(() => {
    setExpanded(false);
  }, [isAILoading]);

  const handleExplanationClick = () => {
    if (!isAILoading) {
      setExpanded(!expanded);
    }
  };

  const explanationBoxStyle = {
    minHeight: "4.5em",
    maxHeight: expanded ? "none" : "4.5em",
    overflow: "hidden",
    position: "relative",
  };

  const loadingStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "4.5em",
  };

  const cardClassName = `explanation-card${
    isOverflowing && !expanded ? " show-ellipsis" : ""
  }`;

  return (
    <Card
      className={cardClassName}
      onClick={handleExplanationClick}
      style={{ cursor: isAILoading ? "default" : "pointer" }}
    >
      <Card.Body>
        {isAILoading ? (
          <div style={loadingStyle}>
            <Spinner animation="grow" size="sm" className="me-2" />
            Trying to explain with AI...
          </div>
        ) : (
          <div style={explanationBoxStyle} ref={textContainerRef}>
            <Card.Text style={{ height: "100%", marginBottom: 0 }}>
              <Image src={AIBrainImg} width={13} className="mb-1" />{" "}
              {currentExplanation}
            </Card.Text>
          </div>
        )}
      </Card.Body>
    </Card>
  );
}

// Function to decode Unicode escape sequences
function decodeUnicodeEscapeSequences(input) {
  return input.replace(/\\u[\dA-Fa-f]{4}/g, (match) =>
    String.fromCharCode(parseInt(match.substr(2), 16))
  );
}

function escapeQuotesAndNewlines(input) {
  return input
    .replace(/\\"/g, '"')        // Replace escaped quotes
    .replace(/\\n/g, '\n');      // Replace escaped newlines
}



export default TranslationPanel;
