import React, { useState } from "react";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BookmarkPlus, BookmarkCheckFill } from "react-bootstrap-icons";
import PlaceholderImg from "../chalice-150x150.png";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";

function getBadgeColor(percentage_unknown_words) {
  if (percentage_unknown_words === 0) {
    return "secondary";
  } else if (percentage_unknown_words <= 33) {
    return "success";
  } else if (percentage_unknown_words <= 66) {
    return "warning";
  } else {
    return "primary"; // This covers the case where percentage_unknown_words > 50
  }
}

export default function LibraryCard({ article, index, is_imported, is_bookmarked=false }) {
  const context = React.useContext(AuthContext);

  async function addArticleToUserLibrary(
    articleId,
    percentComplete = 0,
    wordsRead = 0
  ) {
    setAddBookmarkLoading(true);
    // get the selected language pair ID from the context
    let languagePairId;
    if (context.language_pairs) {
      for (const pair of context.language_pairs) {
        console.log("Selected language pair (in Reader): ", pair);
        if (pair.is_selected) {
          languagePairId = pair._id;
          break;
        }
      }
    }
    console.log("Language pair ID is (in Reader): ", languagePairId);
  
    try {
      console.log("Trying to add article to user library.");
      const response = await axios.post("/api/user/add-article-to-library", {
        article_id: articleId,
        language_pair_id: languagePairId,
        percent_complete: percentComplete,
        words_read: wordsRead,
      });
      console.log("Response from adding article to user library: ", response);
      await context.fetchStudyArticles();
      setAddBookmarkLoading(false);
      // TODO: update Continue Studying articles
    } catch (error) {
      setAddBookmarkLoading(false);
      console.error(
        "There was a problem with adding the article to the user library:",
        error
      );
    }
  }

  const wordCount = article.body.split(" ").length;
  const readingTime = Math.round(wordCount / 100);


  const [addBookmarkLoading, setAddBookmarkLoading] = useState(false);
  const [displayedTitle, setDisplayedTitle] = useState("");

  const handleTitleMouseOver = () => {
    setDisplayedTitle(article.title); // Set to original title on mouseover
  };

  const handleTitleMouseOut = () => {
    setDisplayedTitle(article.title_translated_en || article.title); // Revert back to translated or original title
  };

  return (
    <Col key={index} xs={12} className="mb-4">
      <Card>
        <Card.Body>
          <Row className="flex-column flex-md-row">
            <Col xs={12} md={2}>
              <div
                style={{
                  width: "100%",
                  paddingBottom: "56.25%",
                  position: "relative",
                }}
                className="mb-3 mb-md-0"
              >
                <Link
                  to={`/reader/${article._id}`}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <Card.Img
                    variant="top"
                    src={article.image || PlaceholderImg}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      objectFit: article.image ? "cover" : "scale-down",
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite callback loop
                      e.target.src = PlaceholderImg; // Replace with placeholder image
                      e.target.style.objectFit = "scale-down"; // Change objectFit to scale-down
                    }}
                  />
                </Link>
              </div>
            </Col>
            <Col xs={12} md={10}>
              <Card.Title>
                <Link
                  to={`/reader/${article._id}`}
                  style={{ textDecoration: "none", color: "black" }}
                  onMouseOver={handleTitleMouseOver}
                  onMouseOut={handleTitleMouseOut}
                >
                  {displayedTitle ||
                    article.title_translated_en ||
                    article.title}
                </Link>
              </Card.Title>

              <Card.Subtitle className="mb-2 text-muted">
                {article.publisher_title || "Lingo Champion user"} |{" "}
                <span className="text-none">
                  {article.date_published.slice(0, 10)}
                </span>
              </Card.Subtitle>
              <Card.Text>
                {article.language === "th" ? (
                  <>
                    {wordCount} sentences ({readingTime * 4} minutes){" "}
                  </>
                ) : (
                  <>
                    {wordCount} words ({readingTime} minutes){" "}
                    <Badge bg={getBadgeColor(article.percentage_unknown_words)}>
                      {article.percentage_unknown_words}% new words
                    </Badge>
                  </>
                )}
                {/* converts to user's local timezone */}
                {/* {new Date(article.date_imported).toLocaleString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                            hour12: true,
                          }
                        )} */}
              </Card.Text>
              <Link to={`/reader/${article._id}`}>
                <Button
                  // href={`/reader/${article._id}`}
                  variant="outline-dark"
                  className="btn-sm me-2 mb-2"
                >
                  Read & study
                </Button>
              </Link>
              {is_imported}
              {is_imported && (
                <Link to={`/library/editor/${article._id}`}>
                  <Button
                    // href={`/reader/${article._id}`}
                    variant="outline-dark"
                    className="btn-sm me-2 mb-2"
                  >
                    Edit
                  </Button>
                </Link>
              )}
                <Button
                  variant={is_bookmarked ? "outline-dark" : "outline-dark"}
                  className={`btn-sm me-2 mb-2 ${is_bookmarked ? "disabled" : ""}`}
                  onClick={() => {
                    addArticleToUserLibrary(article._id);
                  }
                }
                >
                  {addBookmarkLoading
                    ? <Spinner animation="grow" size="sm" className="me-2" />
                    : is_bookmarked
                      ? <BookmarkCheckFill color="orange" className="me-2" />
                      : <BookmarkPlus className="me-2" />
                      }
                  
                  {is_bookmarked ? "Bookmarked" : "Bookmark"}
                </Button>
                {addBookmarkLoading && <p>Adding it under "Continue studying"...</p>}
            </Col> 
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}