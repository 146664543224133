import React, { useState, useEffect } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import { Link, useParams, useNavigate } from "react-router-dom";

function LibraryEditor() {
  const context = React.useContext(AuthContext);

  const { id } = useParams(); // Get the 'id' parameter from the URL
  const navigate = useNavigate();

  const [contentTitle, setContentTitle] = useState("");
  const [contentBody, setContentBody] = useState("");
  const [privacySetting, setPrivacySetting] = useState("private");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [importSuccessMessage, setImportSuccessMessage] = useState("");
  const isEditMode = id !== undefined; // Determine if the component is in edit mode or import mode

  useEffect(() => {
    if (isEditMode) {
      // Fetch the article data and populate the form fields
      setIsLoading(true);
      console.log("Trying to fetch article with id: ", id);
      axios
        .get(`/api/articles/${id}`)
        .then((response) => {
          const {
            title = "",
            body = "",
            privacy = "private",
          } = response.data.article;
          setContentTitle(title);
          setContentBody(body);
          setPrivacySetting(privacy);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching article:", error);
          setError("Error occurred while fetching article: " + error.message);
          setContentTitle(""); // Set default values
          setContentBody("");
          setPrivacySetting("private");
          setIsLoading(false);
        });
    }
  }, [id, isEditMode]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleTitleChange = (event) => {
    setContentTitle(event.target.value);
  };

  const handleBodyChange = (event) => {
    setContentBody(event.target.value);
  };

  const handlePrivacyChange = (event) => {
    setPrivacySetting(event.target.value);
  };

  const validateInput = () => {
    if (contentTitle.length < 4 || contentTitle.length > 60) {
      setError("Title must be between 4 and 60 characters.");
      return false;
    }

    if (contentBody.length < 10 || contentBody.length > 10000) {
      setError("Content body must be between 10 and 10,0000 characters.");
      return false;
    }

    return true;
  };

  const submitArticle = () => {
    if (!validateInput()) {
      return;
    }

    setError("");
    setImportSuccessMessage("");
    setIsLoading(true);

    const articleData = {
      title: contentTitle,
      body: contentBody,
      privacy: privacySetting,
      source: "webapp",
      language: context.getSelectedLanguagePair().language_learning.code,
    };

    const request = isEditMode
      ? axios.put(`/api/user/articles/${id}`, articleData)
      : axios.post("/api/user/articles", articleData);

    request
      .then((response) => {
        console.log(
          isEditMode ? "Update successful:" : "Import successful:",
          response.data
        );
        setImportSuccessMessage(
          `Article ${isEditMode ? "updated" : "saved"} successfully.`
        );
        setIsLoading(false);
        if (!isEditMode) {
          navigate("/library?tab=imported"); // Redirect to /library after successful import
        }
      })
      .catch((error) => {
        console.error(
          isEditMode ? "Error updating article:" : "Error importing article:",
          error
        );
        setError(
          `Error occurred while ${
            isEditMode ? "updating" : "importing"
          } article: ` + error.message
        );
        setIsLoading(false);
      });
  };

  const deleteArticle = () => {
    const confirmDeletion = window.confirm(
      "Are you sure you want to delete this article?"
    );
    if (!confirmDeletion) {
      return; // Stop the function if the user cancels the action
    }

    setIsLoading(true); // Set loading state
    setError(""); // Clear any previous error messages

    axios
      .delete(`/api/user/articles/${id}`)
      .then((response) => {
        console.log("Article deleted successfully:", response.data);
        setIsLoading(false);
        navigate("/library?tab=imported"); // Redirect to the library after deletion
      })
      .catch((error) => {
        console.error("Error deleting article:", error);
        setError("Error occurred while deleting the article: " + error.message);
        setIsLoading(false);
      });
  };

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <h2>
        {isEditMode ? "Edit" : "Import"} Content{" "}
        {isLoading && (
          <Spinner animation="border" role="status" className="ms-3">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </h2>
      <Form>
        <Form.Group controlId="formContentImport">
          <Form.Label className="mt-3">Title</Form.Label>
          <Form.Control
            value={contentTitle}
            onChange={handleTitleChange}
            placeholder="Enter title here (max 60 characters)"
          />
          <Form.Label className="mt-3">Content</Form.Label>
          <Form.Control
            as="textarea"
            rows={7}
            value={contentBody}
            onChange={handleBodyChange}
            placeholder="Enter content here (max 10,000 characters)"
          />
          <Row>
            <Col md={4}>
              {" "}
              {/* Adjust the column size as needed */}
              <Form.Select
                value={privacySetting}
                onChange={handlePrivacyChange}
                className="mt-3"
              >
                <option value="private">Private (only you can see it)</option>
                <option value="public">
                  Public (other users can see it too)
                </option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>
        {error && (
          <Alert variant="danger" className="mt-3 mb-0">
            {error}
          </Alert>
        )}
        {importSuccessMessage && (
          <Alert variant="success" className="mt-3 mb-0">
            {importSuccessMessage}
          </Alert>
        )}
        <Button
          variant="primary"
          onClick={submitArticle}
          disabled={isLoading}
          className="my-3"
        >
          {isLoading
            ? isEditMode
              ? "Updating..."
              : "Importing..."
            : isEditMode
            ? "Update"
            : "Import"}
        </Button>
        {isEditMode && (
          <Link to={"/reader/" + id}>
            <Button variant="dark" className="my-3 ms-3">
              Open in Reader
            </Button>
          </Link>
        )}
        {isEditMode && (
          <Button
            variant="danger"
            className="my-3 ms-3"
            onClick={deleteArticle}
          >
            Delete
          </Button>
        )}
      </Form>
    </Container>
  );
}

export default LibraryEditor;
