import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./Player.css";

const AudioSyncText = () => {
  const [currentTime, setCurrentTime] = useState(0);
  const [currentArticle, setCurrentArticle] = useState(null); // [1]
  const audioRef = useRef(null);

  // useffect for fetching the article from the server with axios
  useEffect(() => {
    const articleId = window.location.pathname.split("/").pop();
    console.log("Get the article for articleId:", articleId);
    axios
      .get(`/api/articles/${articleId}`)
      .then((response) => {
        response.data.article.audio_timestamps = JSON.parse(
          response.data.article.audio_timestamps
        );
        setCurrentArticle(response.data.article); // Assuming your API returns the article data
        console.log("Got the article from backend: ", response.data.article);
        // console.log("Received article data:", articleData);
        // addArticleToUserLibrary(response.data.article._id);
        // send a track request to server
        axios
          .post("/api/articles/audio-played", {
            article_id: response.data.article._id,
          })
          .then((response) => {
            console.log("Tracked the audio played event: ", response.data);
          })
          .catch((error) => {
            console.error("Error tracking the audio played event: ", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching article:", error);
        // Handle the error appropriately
      });
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("seeked", handleTimeUpdate);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("seeked", handleTimeUpdate);
    };
  }, []);

  const getHighlightedSentenceIndex = () =>
    currentArticle?.audio_timestamps?.findIndex(
      ({ start, end }) => currentTime >= start && currentTime <= end
    ) ?? -1;

  const handleSentenceClick = (startTime) => {
    console.log("Seeking to:", startTime);
    const audio = audioRef.current;
    console.log("Audio readyState:", audio.readyState);
    // print out seekable ranges
    for (let i = 0; i < audio.seekable.length; i++) {
      console.log(
        "Seekable start:",
        audio.seekable.start(i),
        "Seekable end:",
        audio.seekable.end(i)
      );
    }
    if (audio) {
      const seekAudio = () => {
        audio.currentTime = startTime;
        console.log("Current time after seeking:", audio.currentTime);
        if (audio.paused) {
          audio.play();
        }
      };

      if (audio.readyState >= 2) {
        // HAVE_CURRENT_DATA or better
        seekAudio();
      } else {
        // Wait for the audio to be ready before seeking
        const onLoadedMetadata = () => {
          seekAudio();
          audio.removeEventListener("loadedmetadata", onLoadedMetadata);
        };
        audio.addEventListener("loadedmetadata", onLoadedMetadata);
      }
    }
  };

  useEffect(() => {
    const index = getHighlightedSentenceIndex();
    if (index !== -1) {
      const element = document.getElementById(`sentence-${index}`);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, [currentTime]);

  return (
    <div
      style={{
        paddingBottom: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "90%",
          maxWidth: "600px",
          margin: "0 auto",
          marginBottom: "120px",
        }}
        className="readerText"
      >
        {currentArticle &&
          currentArticle.audio_timestamps.map((sentence, index) => (
            <p
              key={index}
              id={`sentence-${index}`}
              style={{
                backgroundColor:
                  getHighlightedSentenceIndex() === index
                    ? "yellow"
                    : "transparent",
                margin: "10px 0", // Adjust vertical spacing
                padding: "10px",
                cursor: "pointer", // Change cursor to indicate clickability
                fontWeight: index === 0 ? "bold" : "normal",
              }}
              onClick={() => handleSentenceClick(sentence.start)}
            >
              {sentence.sentence}
            </p>
          ))}
      </div>
      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          padding: "20px",
          backgroundColor: "#fff",
          borderTop: "1px solid #ccc",
          textAlign: "center",
          zIndex: 1000,
        }}
      >
        <audio
          ref={audioRef}
          src={currentArticle?.audio_url}
          controls
          style={{ width: "100%", maxWidth: "600px", margin: "0 auto" }}
        />
      </div>
    </div>
  );
};

export default AudioSyncText;
