import React, { useContext, useEffect } from "react";
import { VolumeUp } from "react-bootstrap-icons";
import axios from "axios";
import AuthContext from "../context/AuthContext"; // Assuming this is the correct path
import "./SpeechButton.css";

const SpeechButton = ({ word, speakOnRender }) => {
  const context = useContext(AuthContext);

  useEffect(() => {
    console.log("Speaking on render:" + speakOnRender);
    if (speakOnRender) {
      speakOutWord(word);
      return;
      // logic with browser-based TTS
      if (speechSynthesis.getVoices().length === 0) {
        // wait 100ms for voices to be loaded (adding listeners doesn't work it seems)
        speechSynthesis.addEventListener("voiceschanged", () => {
          console.log(
            "Voices changed (meaning, they got loaded): ",
            speechSynthesis.getVoices()
          );
          speakOutWord(word);
        });
      } else {
        // null;
      }
    }
  }, [speakOnRender, word]);

  function speakOutWord(wordToSpeak) {
    console.log("TTS for the word: ", wordToSpeak);

    // Check if the selected language is Estonian ('et')
    if (context.getSelectedLanguagePair().language_learning.code === "et") {
      // Use TartuNLP TTS API for Estonian
      axios
        .post(
          "https://api.tartunlp.ai/text-to-speech/v2",
          {
            text: wordToSpeak,
            speaker: "mari",
            speed: 0.8,
          },
          { responseType: "arraybuffer" }
        )
        .then((response) => {
          const audioContext = new (window.AudioContext ||
            window.webkitAudioContext)();
          audioContext
            .decodeAudioData(response.data)
            .then((decodedData) => {
              const source = audioContext.createBufferSource();
              source.buffer = decodedData;
              source.connect(audioContext.destination);
              source.start(0);
            })
            .catch((e) => console.error("Error decoding audio data:", e));
        })
        .catch((error) => {
          console.error("Error with Estonian TTS API:", error);
        });
    } else {
      playAudioFromServer(
        wordToSpeak,
        context.getSelectedLanguagePair().language_learning.code
      );
      return;

      // Browser-based TTS logic
      const utterance = new SpeechSynthesisUtterance(wordToSpeak);
      let voices = speechSynthesis.getVoices();
      console.log("These are the browser TTS voices: ", voices);
      if (
        !voices.some(
          (voice) =>
            voice.lang.slice(0, 2) ===
            context.getSelectedLanguagePair().language_learning.code.slice(0, 2)
        )
      ) {
        console.log(
          "Language not supported by browser TTS: ",
          context.getSelectedLanguagePair().language_learning.code
        );
      }
      utterance.lang = context.getSelectedLanguagePair().language_learning.code;
      // Additional properties for the utterance can be set here

      speechSynthesis.speak(utterance);
    }
  }

  async function playAudioFromServer(text, language) {
    console.log("Trying to get TTS from the server...");
    try {
      const response = await axios({
        method: "post",
        url: "/api/text-to-speech",
        responseType: "blob", // Receive response as a blob
        data: {
          text: text,
          language: language,
        },
      });

      const audioBlob = new Blob([response.data], { type: "audio/mpeg" });
      const audioUrl = URL.createObjectURL(audioBlob);
      // Play the audio
      const audio = new Audio(audioUrl);
      audio.onerror = () => {
        console.error("Error occurred when trying to play the audio.");
      };
      audio
        .play()
        .catch((error) => console.error("Error playing audio:", error));
    } catch (error) {
      console.error("Error fetching audio:", error);
      // Handle the error appropriately
    }
  }

  // ... rest of your component logic

  return (
    <VolumeUp
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        speakOutWord(word);
      }}
      onTouchStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className="speakWordButton me-1"
    />
  );
};

export default SpeechButton;
