import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Table,
  OverlayTrigger,
  Tooltip,
  Spinner,
  Alert,
  Badge,
  Dropdown,
} from "react-bootstrap";
import {
  QuestionCircle,
  Wallet2,
  Trash,
  BrowserChrome,
  Pencil,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import AppMetaDataContext from "../context/AppMetaDataContext";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import SpeechButton from "../Components/SpeechButton";
import WordEditModal from "../Components/WordEditModal";
import axios from "axios";
import "./Vocabulary.css";
import AuthContext from "../context/AuthContext";

export default function Vocabulary() {
  const metaData = React.useContext(AppMetaDataContext);
  const context = React.useContext(AuthContext);

  let navigate = useNavigate();

  // Initialize state
  const [learningWords, setLearningWords] = useState([]);
  const [skippedWords, setSkippedWords] = useState([]);
  const [knownWordsArray, setKnownWordsArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showWordEditModal, setShowWordEditModal] = useState(false);
  const [wordToEdit, setWordToEdit] = useState({});

  useEffect(() => {
    setIsLoading(true);
    context.fetchKnownWords().then(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    console.log("Known words array in the context changed. Updating state.");
    setKnownWordsArray(context.known_words);
  }, [context.known_words]);

  // Set state with imported articles data
  useEffect(() => {
    if (knownWordsArray === undefined) return;
    // filter out words that have strength and where ignored is not true
    const filteredLearningWords = knownWordsArray.filter(
      (word) => word.translation
    );
    setLearningWords(filteredLearningWords);

    const filteredSkippedWords = knownWordsArray.filter(
      (word) => !word.translation
    );
    setSkippedWords(filteredSkippedWords);
  }, [knownWordsArray]);

  async function deleteWord(e) {
    e.preventDefault();
    const wordId = e.target.dataset.id;
    console.log("Deleting word with id: ", wordId);
    // remove the word from the state
    // NOTE: this is not the best way to do it, cause the deletion is not guaranteed
    // but it gives immediate feedback to the user at least and if it fails, the page will be refreshed
    setLearningWords(learningWords.filter((word) => word._id !== wordId));
    let wordsToDelete = [];
    wordsToDelete.push(wordId);
    sendWordsToDeleteToBackend(wordsToDelete)
      .then(() => {
        console.log("Word deleted successfully.");
        // NOTE: I'm not going to refresh the words here, cause it's pretty much guaranteed the word was deleted
        // NOTE: maybe should do it to have the latest data for other views too. Dunno
        // update the known words
        // context.fetchKnownWords().then(() => {
        //   setKnownWordsArray(context.known_words);
        // });
      })
      .catch((err) => {
        console.error("Error when deleting word: ", err);
      });
  }

  async function sendWordsToDeleteToBackend(wordsToDelete) {
    return new Promise((resolve, reject) => {
      if (wordsToDelete.length === 0) {
        console.log("No words to delete in the backend.");
        resolve(false);
      } else {
        console.log(
          "Sending these words to backend for deletion: ",
          wordsToDelete
        );
        // send a request to the api to update the word
        // NOTE: for delete requests, the payload has to be in the config.data property
        // ideally I would use the :id in the url but it wouldn't support deleting multiple words at once
        const config = {
          data: { words: wordsToDelete },
        };
        axios
          .delete("/api/user/delete-words", config)
          .then((response) => {
            console.log("RESPONSE FROM DELETE WORDS backend: ", response.data);
            resolve(true);
          })
          .catch((err) => {
            console.error("ERROR WHEN DELETE WORDS in backend:", err);
            reject(err);
          });
      }
    });
  }

  function handleWordEditClick(word) {
    console.log("Edit word clicked: ", word);
    setWordToEdit(word);
    setShowWordEditModal(true);
  }

  return (
    <Container
      className="d-flex pt-5 flex-column justify-content-top"
      style={{ minHeight: "70vh" }}
    >
      <Helmet>
        <title>Vocabulary - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>
      <div>
        <div className="" style={{ maxWidth: "700px" }}>
          {/* <h1 className="mb-5">Vocabulary</h1> */}
        </div>

        <Alert variant="secondary" className="mb-5">
          <Alert.Heading>
            Chrome browser extension <sup>beta</sup>
          </Alert.Heading>
          <div>
            Use the browser extension to:
            <ul>
              <li>
                Translate your learning words automatically on any website you
                visit
              </li>
              <li>
                Import captions/subtitles of any YouTube video to study them
                later
              </li>
              <li>
                Translate YouTube caption by hovering on them{" "}
                <sup>
                  <Badge bg="primary" text="light">
                    New!
                  </Badge>
                </sup>
              </li>
              <li>
                Import content from any website to study it in Lingo Champion{" "}
                <sup>
                  <Badge bg="warning" text="dark">
                    Coming soon!
                  </Badge>
                </sup>
              </li>
            </ul>
          </div>
          <p className="mb-0">
            The Chrome browser extension is now in beta. It works also with
            Microsoft Edge, Brave, and Opera.
          </p>
          <hr />

          <div className="d-flex justify-content-start">
            <Button
              variant="dark"
              href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
              target="_blank"
            >
              Get the Chrome extension
            </Button>
          </div>
        </Alert>

        {isLoading && (
          <div className="d-flex justify-content-center mb-5">
            <div className="d-flex flex-column">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          </div>
        )}

        <h3>Learning words list</h3>
        <div className="mb-4 align-items-end">
          <Link to="/vocabulary/import">
            <Button size="sm" className="me-2 mt-2">
              &#43; Import words
            </Button>
          </Link>
          <Dropdown className="me-2 mt-2 d-inline-block align-bottom">
            <Dropdown.Toggle
              variant="warning"
              style={{ fontWeight: 400 }}
              size="sm"
            >
              <Wallet2
                style={{ transform: "rotate(-90deg)", marginBottom: "2px" }}
              />{" "}
              Review with flashcards
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => navigate("/vocabulary/review?type=general")}
                disabled={learningWords.length === 0}
              >
                <strong>General flashcards</strong> (lowest strength first)
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => navigate("/vocabulary/review?type=srs")}
                disabled={learningWords.length === 0}
              >
                <strong>Spaced Repetition System</strong> (overdue today first,
                then by earliest overdue date)
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Button
            size="sm"
            variant="outline-dark"
            className="mt-2"
            href="https://chromewebstore.google.com/detail/lingo-champion/jjbaohhbimlnboamjmdbblhjomhnipff?hl=en-GB"
            target="_blank"
          >
            <BrowserChrome className="me-2" style={{ marginBottom: "0.2em" }} />
            Learn with the Chrome extension
          </Button>
        </div>

        {/* display words in a table with rows: word, translation, strength */}
<>
    <Table striped bordered hover size="sm" responsive="sm" className="d-none d-sm-table">
      <thead>
        <tr>
          <th>Word</th>
          <th>Translation</th>
          <th>
            Strength
            <sup>
              <OverlayTrigger
                placement="left"
                delay={{ show: 50, hide: 100 }}
                overlay={
                  <Tooltip style={{ zIndex: 20000 }}>
                    Words can have a learning strength between 0 and 4 (max). The strength is used for selecting the words for
                    the flashcards (weakest ones first).
                  </Tooltip>
                }
              >
                <QuestionCircle size="1.2em" className="ms-1 p-0" />
              </OverlayTrigger>
            </sup>
          </th>
          <th>
            SRS due date
            <sup>
              <OverlayTrigger
                placement="left"
                delay={{ show: 50, hide: 100 }}
                overlay={
                  <Tooltip style={{ zIndex: 20000 }}>
                    Shows when the word is due for review in the Spaced
                    Repetition System with the flashcards.
                  </Tooltip>
                }
              >
                <QuestionCircle size="1.2em" className="ms-1 p-0" />
              </OverlayTrigger>
            </sup>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {learningWords.map((word, index) => (
          <tr key={index}>
            <td>
              <SpeechButton word={word.word} speakOnRender={false} />
              <span className="wordEditLink fw-bold" onClick={() => handleWordEditClick(word)}>{word.word}</span>
            </td>
            <td>{word.translation}</td>
            <td>{word.strength}</td>
            <td>{word.srs_due_date && word.srs_due_date.split("T")[0]}</td>
            <td className="text-center">
              <Trash
                className="deleteWordIcon"
                data-id={word._id}
                onClick={deleteWord}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

    <div className="d-sm-none">
      {learningWords.map((word, index) => (
        <div key={index} className="mb-3 p-2 border rounded">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between">
              <span>
                <SpeechButton word={word.word} speakOnRender={false} />
                <span className="wordEditLink fw-bold" onClick={() => handleWordEditClick(word)}>{word.word}</span>
                
              </span>
            </div>
            <div className="d-flex justify-content-between">

              <span>{word.translation}</span>
            </div>
            <div className="d-flex justify-content-between mt-2">

              <span><span>Strength: </span>{word.strength}</span>
            </div>
            <div className="d-flex justify-content-between">

              <span><span>SRS Due Date: </span>{word.srs_due_date && word.srs_due_date.split("T")[0]}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="fw-bold"></span>
              <span>
                  <Pencil className="wordEditLink me-3" data-id={word._id} onClick={() => handleWordEditClick(word)} />
                  <Trash
                    className="deleteWordIcon"
                    data-id={word._id}
                    onClick={deleteWord}
                  />
                </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  </>
      </div>
      <WordEditModal show={showWordEditModal} setShow={setShowWordEditModal} knownWordsArray={knownWordsArray} wordToEdit={wordToEdit} />
    </Container>
  );
}
