import React, { Component } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export class AuthProvider extends Component {
  state = {
    user_id: undefined,
    email: undefined,
    features: undefined,
    newsletter: undefined,
    plan: undefined,
    // plan_name: undefined,
    has_token: undefined,
    has_been_verified: undefined,
    translations: undefined,
    language_pairs: undefined,
    known_words: undefined, // NOTE: these are the known words for the currently selected language pair
    onboarding_shown: false,
    timezone: undefined,
    statistics: undefined,
    streaks: undefined,
    study_articles: [],
  };

  // Mark a word as known
  markWordAsKnown = (word, translation) => {
    this.setState((prevState) => ({
      known_words: [...prevState.known_words, { word, translation }],
    }));
  };

  saveNewWordToBackend = (word, article_id = undefined) => {
    console.log("Trying to save the new word to the backend: ", word);
    const request = {
      word: word,
      article_id: article_id,
    };
    
    return axios
      .put("/api/user/vocabulary/add", request)
      .then((response) => {
        console.log("RESPONSE VOCABULARY ADD WORDS backend: ", response.data);
        this.setState((prevState) => {
          // Ensure known_words is an array or initialize it as an empty array
          const known_words = Array.isArray(prevState.known_words) ? prevState.known_words : [];
          return {
            known_words: [...known_words, response.data.addedWord],
          };
        });
        console.log("Added new known word: ", response.data.addedWord);
        return response.data;  // Ensure the resolved value of the promise is the response data
      })
      .catch((err) => {
        console.error("ERROR WHEN ADDING WORDS in backend:", err);
        throw err;  // Propagate the error to the caller
      });
  };



  // Update the translation of a known word
  updateWordTranslation = (word, newTranslation) => {
    this.setState((prevState) => {
      const updatedKnownWords = prevState.known_words.map((kw) => {
        if (kw.word === word) {
          return { ...kw, translation: newTranslation };
        }
        return kw;
      });
      return { known_words: updatedKnownWords };
    });
  };

  // Delete a known word
  deleteKnownWord = (word) => {
    this.setState((prevState) => ({
      known_words: prevState.known_words.filter((kw) => kw.word !== word),
    }));
  };

  getAuthInfo = () => {
    //const controller = new AbortController();
    console.log("STATE before Authentication: ", this.state);
    axios
      .get("/api/check-cookies") // , {signal: controller.signal}
      .then((res) => {
        console.log(
          "Auth data sent back from server inside res.data: ",
          res.data
        );
        // NOTE: it can only be undefined for the old users - the new ones will have the timezone set
        // NOTE: some older browsers might not support getting timezone, so setting it to UTC as a fallback
        const currentTimezone =
          Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
        if (res.data.timezone === undefined && res.data.user_id) {
          console.log("No timezone was found in the response data");
          // set the timezone in the state
          // NOTE: this is maybe not technically needed as the updateTimeZone will update the state as well
          res.data.timezone = currentTimezone;

          // send the timezone to the backend
          this.updateTimeZone(currentTimezone);
        }
        //console.log("YO: ", res.data.verified);
        // store user in the Context to make it available for all the children
        if (res.data.has_token !== "undefined") {
          // find the selected language pairs
          // find the known words
          function findSelectedWords(data) {
            if (!data.language_pairs) {
              return [];
            }
            for (const pair of data.language_pairs) {
              if (pair.is_selected) {
                return pair.words;
              }
            }
            return [];
          }
          const selectedWords = findSelectedWords(res.data);

          this.setState(
            {
              user_id: res.data.user_id,
              email: res.data.email,
              features: res.data.features,
              newsletter: res.data.newsletter,
              notifications: res.data.notifications,
              plan: res.data.plan,
              has_token: res.data.has_token,
              has_been_verified: res.data.has_been_verified,
              translations: res.data.translations,
              language_pairs: res.data.language_pairs,
              // known_words: selectedWords,
              timezone: res.data.timezone,
            },
            () => {
              console.log(
                "Double check the state after adding values from the res.data: ",
                this.state
              );
            }
          );
        } else {
          this.setState({
            has_token: false,
          });
        }
        console.log(
          "AuthContext.js: User global context has been set to: ",
          this.state
        );
      })
      .catch((err) => {
        console.log(err);
      });
    //return controller;
  };

  updateTimeZone = (timezone) => {
    axios
      .post("/api/user/update-timezone", { timezone: timezone })
      .then((response) => {
        // Handle the response, e.g., update the time zone if it differs
        console.log("Timezone update response:", response.data);
        this.setState({ timezone });
        return response.data;
      })
      .catch((error) => {
        console.error("Error updating time zone:", error);
      });
  };

  // Method to fetch known words
  fetchKnownWords = async () => {
    return new Promise((resolve, reject) => {
      axios
        .get("/api/user/known-words") // replace with your actual API endpoint
        .then((response) => {
          this.setState({ known_words: response.data.words }, () => {
            console.log(
              response.data.words.length,
              "known words fetched: ",
              response.data.message
            );
            resolve(response.data.words); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching known words:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  updateKnownWordInContext = (wordToUpdate) => {
    if (!this.state || !this.state.known_words) {
      console.error("State or known_words is undefined");
      return;
    }

    const { known_words } = this.state;
    const updatedKnownWords = known_words.map((kw) => {
      if (kw._id === wordToUpdate._id) {
        return wordToUpdate;
      }
      return kw;
    });

    this.setState({ known_words: updatedKnownWords });
  };

  // fetch study articles
  fetchStudyArticles = async () => {
    return new Promise((resolve, reject) => {
      // get the language pair learning language code
      let languageLearningCode = this.getSelectedLanguagePair().language_learning.code;
      console.log("Trying to fetch study articles for language: ", languageLearningCode);
              
      axios
        .get("/api/user/articles", {
          params: {
            language: languageLearningCode,
          },
        })
        .then((response) => {
          this.setState({ study_articles: response.data.articles }, () => {
            console.log(
              response.data.articles.length,
              "study articles fetched: ",
              response.data.message
            );
            resolve(response.data.articles); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching study articles:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  // NOTE: this sets statistics to an object (it's not an array)
  fetchStatistics = async () => {
    return new Promise((resolve, reject) => {
      let languagePairId = this.getSelectedLanguagePair()._id;
      axios
        .get(`/api/user/language-pairs/${languagePairId}/statistics`) // replace with your actual API endpoint
        .then((response) => {
          this.setState({ statistics: response.data }, () => {
            console.log("Fetched the statistics: ", response.data);
            resolve(response.data); // Resolve the promise after state update
          });
        })
        .catch((error) => {
          console.error("Error fetching the statistics:", error);
          reject(error); // Reject the promise on error
        });
    });
  };

  setOnboardingShownToTrue = () => {
    this.setState({ onboarding_shown: true });
  };

  // Add a new course
  addCourse = (courseData) => {
    axios
      .post("/api/courses", courseData)
      .then(() => {
        this.getAuthInfo(); // Refresh auth info after adding a course
      })
      .catch((error) => {
        console.error("Error adding course:", error);
      });
  };

  // Delete a course
  // NOTE: have to make sure that it's not possible to delete the last course
  deleteCourse = (courseId) => {
    axios
      .delete(`/api/courses/${courseId}`)
      .then(() => {
        this.getAuthInfo(); // Refresh auth info after deleting a course
      })
      .catch((error) => {
        console.error("Error deleting course:", error);
      });
  };

  // Set a course as active
  setActiveCourse = (courseId) => {
    console.log(
      "Trying to set the active course in context and backend to: ",
      courseId
    );
    // make axios call to backend
    axios
      .post(`/api/user/courses/active/${courseId}`)
      .then((res) => {
        console.log(
          "Active course set successfully with message: ",
          res.message
        );
        // force rerender of the page bc the Library wouldn't refresh itself currently (it doesn't use context)
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error setting active course:", error);
      });
  };

  // get the currently selected language pair
  getSelectedLanguagePair = () => {
    const { language_pairs } = this.state;
    for (const pair of language_pairs) {
      if (pair.is_selected) {
        return pair;
      }
    }
    return null;
  };

  render() {
    const {
      user_id,
      plan,
      email,
      features,
      newsletter,
      notifications,
      has_token,
      has_been_verified,
      translations,
      language_pairs,
      known_words,
      onboarding_shown,
      timezone,
      statistics,
      streaks,
      study_articles,
    } = this.state;

    const {
      markWordAsKnown,
      updateWordTranslation,
      deleteKnownWord,
      fetchKnownWords,
      saveNewWordToBackend,
      updateKnownWordInContext,
      fetchStatistics,
      setOnboardingShownToTrue,
      addCourse,
      deleteCourse,
      setActiveCourse,
      getSelectedLanguagePair,
      updateTimeZone,
      fetchStudyArticles,
    } = this;

    const { getAuthInfo } = this; // const {logOut} = this;  // this for later for having the logout function available in context

    return (
      <AuthContext.Provider
        value={{
          user_id,
          email,
          features,
          newsletter,
          notifications,
          plan,
          has_token,
          has_been_verified,
          translations,
          language_pairs,
          known_words,
          onboarding_shown,
          timezone,
          statistics,
          streaks,
          study_articles,
          markWordAsKnown,
          updateWordTranslation,
          deleteKnownWord,
          getAuthInfo,
          updateTimeZone,
          fetchKnownWords,
          updateKnownWordInContext,
          saveNewWordToBackend,
          fetchStatistics,
          setOnboardingShownToTrue,
          addCourse,
          deleteCourse,
          setActiveCourse,
          getSelectedLanguagePair,
          fetchStudyArticles,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
