// Function to split a string by URLs and keep them as tokens
const splitByURLs = (str) => {
  const regex = /(https?:\/\/[^\s]+)/g;
  let match;
  let lastIndex = 0;
  const result = [];

  while ((match = regex.exec(str)) !== null) {
    // Push non-URL part
    result.push(str.slice(lastIndex, match.index));
    // Push URL
    result.push(match[0]);
    lastIndex = regex.lastIndex;
  }

  // Push remaining non-URL part
  result.push(str.slice(lastIndex));

  return result;
};

function createWords(wordsArray, knownWords, inputType) {
  // create a new array of words
  let words = [];
  // loop through wordsArray
  for (let i = 0; i < wordsArray.length; i++) {
    // create a new word object
    let word = {};
    // add the word to the word object
    word.word = wordsArray[i];
    // add the known property to the word object
    // set the known property to false
    word.known = false;
    word.is_punctuation_or_space_or_number = false;
    if (inputType === "title") {
      word.is_title = true;
      if (i === wordsArray.length - 1) {
        word.is_last_title_word = true;
      }
    } else {
      word.is_title = false;
    }
    // check if the word is punctuation or space or contains a number with regex
    const regex = /[\p{P}\p{Z}\p{N}]/gu;
    const got_punctuation = word.word.match(regex);
    if (got_punctuation) {
      word.is_punctuation_or_space_or_number = true;
      word.known = undefined;
    } else {
      const known = checkIfWordIsKnown(word.word, knownWords || []);
      // set the known property to true
      if (known) {
        word.known = true;
        word.translation = known.translation;
      }
    }
    // add the word object to the words array
    words.push(word);
  }
  return words;
}

function checkIfWordIsKnown(wordToCompare, knownWordsArray) {
  // Convert the word to lowercase for comparison
  const wordToCompareLowerCase = wordToCompare.toLowerCase();

  // Search for a match in the lowercase "word" properties of knownWordsArray
  // NOTE: this sometimes crashed the app because the word property was undefined
  // const foundWord = knownWordsArray.find(
  //   (wordObj) => wordObj.word.toLowerCase() === wordToCompareLowerCase
  // );

  const foundWord = knownWordsArray.find(
    (wordObj) =>
      wordObj.word && wordObj.word.toLowerCase() === wordToCompareLowerCase
  );

  if (foundWord) {
    // console.log("FOUND WORD: ", foundWord);
  }

  // If a match is found, return the word; otherwise, return false
  return foundWord ? foundWord : false;
}

// NOTE: these are actually used:

// Helper function to split text into sentences
function splitIntoSentences(text) {
  return text.match(/[^\.!\?]+[\.!\?]*|.+$/g) || [];
}

// Helper function to split text into tokens
function splitIntoTokens(text, sentence) {
  return text
    .split(/(\s+|\p{P}+|\p{L}+(?:\p{M}*\p{N}*)*)/gu)
    .filter((token) => token !== "")
    .map((token) => ({ token, sentence }));
}

function escapeHTML(str) {
  return str
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;");
}

// this is for checking if the --translation-panel property exists (to see if the translation panel was clicked)
function checkTranslationPanelProp(element) {
  if (!element) return false;
  const computedStyle = window.getComputedStyle(element);
  const translationPanelValue = computedStyle
    .getPropertyValue("--translation-panel")
    .trim();

  return translationPanelValue !== ""; // return true if the property exists and has a value
}

function sendBugReport() {
  // open an url
  window.open("https://tally.so/r/wbW5X1", "_blank");
}

function openSupportCenter() {
  // open an url
  window.open("https://lingochampion.freshdesk.com", "_blank");
}

export {
  splitByURLs,
  createWords,
  checkIfWordIsKnown,
  splitIntoSentences,
  splitIntoTokens,
  checkTranslationPanelProp,
  escapeHTML,
  sendBugReport,
  openSupportCenter,
};
