import React, { useRef, useState, useEffect } from "react";
import { Form, Button, Card, Alert } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import AppMetaDataContext from "../context/AppMetaDataContext";
import ReactGA from "react-ga4";

export default function Register() {
  const metaData = React.useContext(AppMetaDataContext);

  const [languages, setLanguages] = useState([]); // State to store fetched languages
  const [speakLang, setSpeakLang] = useState("");
  const [learnLang, setLearnLang] = useState("");
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [speakLanguageInfoMessage, setSpeakLanguageInfoMessage] =
    useState(false);
  const [currentLevel, setCurrentLevel] = useState("");
  // after backend registration success use this state to redirect to /login form
  const [shouldRedirect, setShouldRedirect] = useState(false);
  // newsletter checkbox
  const [shouldCheck, setShouldCheck] = useState(false);

  const currentLevelRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const newsletterRef = useRef();

  const levels = [
    { id: 1, name: "Newbie (haven't started learning it yet)" },
    { id: 2, name: "Beginner (know some basics)" },
    { id: 3, name: "Intermediate (can understand familiar topics)" },
    { id: 4, name: "Advanced (fluent in most topics)" },
  ];

  // Fetch and sort languages when component mounts
  useEffect(() => {
    axios
      .get("/api/languages")
      .then((response) => {
        const sortedLanguages = response.data.languages.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        setLanguages(sortedLanguages);
      })
      .catch((error) => {
        console.error("Error fetching languages:", error);
      });
  }, []);

  // useEffect for newsletter checkbox
  useEffect(() => {
    axios
      .get("/api/check-ip-for-newsletter")
      .then((response) => {
        // Assuming the endpoint returns true or false
        setShouldCheck(response.data);
      })
      .catch((error) => {
        console.error(
          "There was an error with checking the IP for newsletter!",
          error
        );
      });
  }, []);

  // useEffect for speakLang
  useEffect(() => {
    if (speakLang !== "en" && speakLang !== "" && learnLang !== "en") {
      return;
      setSpeakLanguageInfoMessage(
        "Note that English is probably a more accurate base language. But you can switch and add languages at any time after registration as well."
      );
    } else {
      setSpeakLanguageInfoMessage(false);
    }
    if (speakLang === learnLang && speakLang !== "" && learnLang !== "") {
      // setSpeakLanguageInfoMessage(
      //   "Note that you selected the same language for learning and speaking. This is okay, but you will not be able to use the translation feature - only the AI explanations will be available. You can switch and add languages at any time after registration as well."
      // );
      setSpeakLanguageInfoMessage(
        "You selected the same language for learning and speaking. It works but are you sure you want to do that?"
      );
    }
  }, [speakLang, learnLang]);

  const handleSubmit = async (data) => {
    data.preventDefault();
    // if speakLang or learnLang is empty, show an error message
    if (!speakLang || !learnLang) {
      setErrorMessage("Please select the languages you speak and learn.");
      return;
    }

    console.log("Register form submitted with email: ", emailRef.current.value);

    const body = {
      email: emailRef.current.value,
      password: passwordRef.current.value,
      newsletter: newsletterRef.current.checked,
      language_learning: learnLang,
      language_base: speakLang,
      current_level: currentLevel,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    console.log("Register API query body.email: ", body.email);
    // send API request to register back-end
    axios
      .post("/api/user/register", body)
      .then((res) => {
        console.log("Register API response: ", res.data);
        setShouldRedirect(true);
        // Sending a track event to Google Analytics
        ReactGA.event({
          category: "setup",
          action: "signed_up",
          label: "signed_up",
        });
      })
      .catch((err) => {
        console.log("Registration failed with message: ", err.response.data);
        setErrorMessage(err.response.data);
      });
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minheight: "100vh" }}
    >
      <Helmet>
        <title>Register - {metaData.appName}</title>
        <meta name="description" content={metaData.metaDesc} />
      </Helmet>

      <div className="w-100" style={{ maxWidth: "400px" }}>
        <Card>
          <Card.Body>
            <h2 className="text-center mb-4">Try it for free</h2>
            <Card.Text className="text-center">
              No credit card required.
            </Card.Text>
            <Form onSubmit={handleSubmit}>
              <Form.Group id="learn-lang" className="my-2">
                <Form.Label>I want to learn</Form.Label>
                <Form.Select
                  onChange={(e) => setLearnLang(e.target.value)}
                  value={learnLang}
                >
                  <option value="" disabled>
                    Select language
                  </option>
                  {languages.map((lang) => (
                    <option key={lang._id} value={lang.code}>
                      {lang.name} {lang.code === "th" && "(in beta)"}
                    </option>
                  ))}
                  {/* Add more options as needed */}
                </Form.Select>
              </Form.Group>

              <Form.Group id="current-level" className="my-2">
                <Form.Label>My current level is</Form.Label>
                <Form.Select
                  ref={currentLevelRef}
                  value={currentLevel}
                  onChange={(e) => setCurrentLevel(e.target.value)}
                >
                  <option value="" disabled>
                    Select your{" "}
                    {!learnLang
                      ? "language"
                      : languages.find((lang) => lang.code === learnLang)
                          ?.name}{" "}
                    level
                  </option>
                  {levels.map((level) => (
                    <option key={level.id} value={level.id}>
                      {level.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group id="speak-lang" className="my-2">
                <Form.Label>I speak</Form.Label>
                <Form.Select
                  onChange={(e) => setSpeakLang(e.target.value)}
                  value={speakLang}
                >
                  <option value="" disabled>
                    Select language
                  </option>
                  {languages.map((lang) => (
                    <option key={lang._id} value={lang.code}>
                      {lang.name}
                    </option>
                  ))}
                  {/* Add more options as needed */}
                </Form.Select>
              </Form.Group>

              {speakLanguageInfoMessage && (
                <Alert variant="secondary" className="my-3">
                  &#128161; {speakLanguageInfoMessage}
                </Alert>
              )}

              <Form.Group id="email" className="my-2">
                <Form.Label>Your email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  ref={emailRef}
                  className="w-20"
                />
              </Form.Group>
              <Form.Group id="password" className="my-2">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" required ref={passwordRef} />
              </Form.Group>
              <Form.Group className="mt-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  checked={shouldCheck}
                  label="Let me know via email about new features"
                  ref={newsletterRef}
                  onChange={() => setShouldCheck(!shouldCheck)}
                />
              </Form.Group>
              <Button type="submit" className="w-100 mt-4">
                Sign up
              </Button>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2">
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          Already have an account? <Link to="/login">Log in</Link>
        </div>
        {shouldRedirect && (
          <div>
            <Navigate to="/login?signup=true" />
          </div>
        )}
      </div>
    </Container>
  );
}
